export const translations = {

    '/': '/',
    '/altersklasse/': '/agegroup/',
    '/altersklasse/:id': '/agegroup/:id',
    '/altersklasse/neu': '/agegroup/new',
    '/altersklassen': '/agegroups',
    '/antraege': '/requests',
    '/dashboard': '/dashboard',
    '/nation/': '/nation/',
    '/nation/:id': '/nation/:id',
    '/nation/neu': '/nation/new',
    '/nationen': '/nations',
    '/person/': '/person/',
    '/person/:id': '/person/:id',
    '/person/neu': '/person/new',
    '/personen': '/persons',
    '/suche': '/search',
    '/suche/': '/search/',
    '/suche/:query': '/search/:query',
    '/verbaende': '/federations',
    '/verband/:id': '/federation/:id',
    '/verband/neu': '/federation/new',
    '/verband/': '/federation/',
    '/verein/': '/club/',
    '/verein/:id': '/club/:id',
    '/verein/neu': '/club/new',
    '/vereine': '/clubs',
    '1. Zielverein wählen': 'Step 1: Select Target Club',
    '2. Spieler:in suchen:': 'Step 2: Search Player',
    'Abgelaufen': 'Expired',
    'Abgelehnt': 'Rejected',
    'Ablehnen': 'Reject',
    'Aktive Lizenzen': 'Active Licenses',
    'Aktiver Leihvertrag vorhanden.': 'Active loan agreement.',
    'Akzeptieren': 'Accept',
    'Akzeptiert': 'Accepted',
    'Alle': 'All',
    'Altersklasse': 'Age Group',
    'Altersklassen': 'Age Groups',
    'Antrag konnte nicht abgelehnt werden.': 'Error rejecting request.',
    'Antrag konnte nicht akzeptiert werden.': 'Error accepting request.',
    'Antrag konnte nicht gesendet werden.': 'Error sending request.',
    'Antrag senden': 'Send Request',
    'Antrag wird abgelehnt...': 'Rejecting request...',
    'Antrag wird akzeptiert...': 'Accepting request...',
    'Antrag wird gesendet...': 'Sending request...',
    'Antrag wurde abgelehnt.': 'Request rejected.',
    'Antrag wurde akzeptiert.': 'Request accepted.',
    'Antrag wurde gesendet.': 'Request sent.',
    'Anträge': 'Requests',
    'Anzeigen': 'Show',
    'April': 'April',
    'August': 'August',
    'Bild zu breit': 'Image too wide',
    'Bild zu hoch': 'Image too high',
    'Bis': 'To',
    'Bitte im Tab "Dateien" hochladen.': 'Please upload in the files tab.',
    'Bitte speichern Sie den Spieler zuerst, um Lizenzen hinzuzufügen.': 'Save player to add licenses.',
    'Bitte speichern Sie den Spieler zuerst, um Transferkarten hinzuzufügen.': 'Save player to add transfer cards.',
    'Bitte speichern Sie die Spielerin zuerst, um Lizenzen hinzuzufügen.': 'Save player to add licenses.',
    'Bitte speichern Sie die Spielerin zuerst, um Transferkarten hinzuzufügen.': 'Save player to add transfer cards.',
    'Bitte verwenden Sie das Suchfeld im Header.': 'Please use the search field above.',
    'breit': 'wide',
    'Dashboard': 'Dashboard',
    'Datei auswählen': 'Select File',
    'Datei herunterladen': 'Download File',
    'Datei löschen': 'Delete File',
    'Datei löschen?': 'Delete file?',
    'Datei wird gelöscht...': 'Deleting file...',
    'Datei wird hochgeladen...': 'Uploading file...',
    'Datei zu groß': 'File too large',
    'Dateien': 'Files',
    'Daten konnten nicht geladen werden.': 'Loading data failed.',
    'Dezember': 'December',
    'Diese Datei ist verpflichtend.': 'This file is required.',
    'Dieses Feld ist verpflichtend.': 'This field is required.',
    'Dokumente': 'Documents',
    'Dokumente übernehmen': 'Transfer Documents',
    'Eintrag': 'Entry',
    'Einträge': 'Entries',
    'Februar': 'February',
    'Fehler': 'Error',
    'Fehler beim Hochladen': 'Error uploading',
    'Fehler beim Löschen': 'Error deleting',
    'Filtern...': 'Filter...',
    'Folgende Dateien müssen für eine Lizenz vorhanden sein:': 'Licenses require the following files:',
    'Für diesen Spieler kann keine Lizenz beantragt werden.': 'It is not possible to request a license for this player.',
    'Für diese Spielerin kann keine Lizenz beantragt werden.': 'It is not possible to request a license for this player.',
    'geboren': 'born',
    'Geburtsdatum': 'Date of Birth',
    'Gelöscht': 'Deleted',
    'geschlecht': 'sex',
    'Geschlecht': 'Sex',
    'Google Maps URL': 'Google Maps URL',
    'Gültig': 'Valid',
    'Hausnummer': 'House Number',
    'hoch': 'high',
    'Installiere...': 'Installing...',
    'Jahr': 'Year',
    'jahrgang': 'yob',
    'Jahrgang': 'Year of Birth',
    'Jänner': 'January',
    'Jetzt installieren': 'Install now',
    'Juli': 'July',
    'Juni': 'June',
    'Keine aktive gültige Lizenz vorhanden.': 'No active valid license.',
    'Keine Berechtigung': 'No Permission',
    'Keine Einträge gefunden.': 'No entries found.',
    'Keine gültige erforderliche Lizenz vorhanden.': 'No valid required license.',
    'Keine Lizenzen vorhanden.': 'No licenses available.',
    'Keine Optionen verfügbar': 'No Options available',
    'Kommentar': 'Comment',
    'Laden': 'Load',
    'Liga': 'League',
    'Links': 'Left',
    'Lizenz': 'License',
    'Lizenz beantragen': 'Request License',
    'Lizenz löschen?': 'Delete License?',
    'Lizenzen': 'Licenses',
    'Lizenzerstausstellung': 'Initial License',
    'Lizenzhistorie': 'License History',
    'Lizenzierung': 'Licensing',
    'Mai': 'May',
    'Männlich': 'Male',
    'März': 'March',
    'Maximale Anzahl an aktiven Lizenzen erreicht.': 'Maximum number of active licenses reached.',
    'Maximale Anzahl an aufeinanderfolgenden Lizenzen erreicht.': 'Maximum number of sequential licenses reached.',
    'Möchten Sie diese Datei wirklich löschen?': 'Do you really want to delete this file?',
    'Möchten Sie diese Lizenz wirklich löschen?': 'Are you sure you want to delete this license?',
    'Möchten Sie diese Transferkarte wirklich löschen?': 'Are you sure you want to delete this transfer card?',
    'Monat': 'Month',
    'nachname': 'last',
    'Nachname': 'Last Name',
    'Nation': 'Nation',
    'Nationen': 'Nations',
    'Neue Datei hochladen': 'Upload new File',
    'Neue Lizenz': 'New License',
    'Neue Transferkarte': 'New Transfer Card',
    'Neuer Antrag': 'New Request',
    'Nicht transferierbare Lizenz (%s) bei %s vorhanden.': 'Non-transferable license (%s) exists for %s.',
    'November': 'November',
    'oder hierherziehen': 'or drop here',
    'Offen': 'Pending',
    'Offene Lizenzanträge': 'Pending License Requests',
    'Offener Lizenzantrag vorhanden.': 'Pending license request.',
    'Offener Transfer-/Lizenzantrag vorhanden.': 'Pending transfer/license request.',
    'Oktober': 'October',
    'Ort': 'City',
    'passnr': 'passno',
    'Passnummer': 'Pass Number',
    'Person': 'Person',
    'Personen': 'Persons',
    'Personen filtern...': 'Filter Persons...',
    'Personen verwalten': 'Manage Persons',
    'PLZ': 'ZIP Code',
    'Rechnungswesen': 'Accounting',
    'Rechts': 'Right',
    'September': 'September',
    'Speichern zum Bearbeiten': 'Save to edit',
    'Spielbetrieb': 'Competitions',
    'Spieler': 'Player',
    'Spieler:in transferieren': 'Transfer Player',
    'Spielerin': 'Player',
    'Spielerlizenzen': 'Player Licenses',
    'Stammdaten': 'Basic Data',
    'Stornieren': 'Cancel',
    'Storniert': 'Canceled',
    'Straße': 'Street',
    'Suche': 'Search',
    'Suchen': 'Search',
    'Suchergebnisse filtern': 'Filter Search Results',
    'Tag': 'Day',
    'Transfer': 'Transfer',
    'Transfer durchführen': 'Transfer',
    'Transfer konnte nicht durchgeführt werden.': 'Error transferring.',
    'Transfer wird durchgeführt...': 'Transferring...',
    'Transfer wurde durchgeführt.': 'Transfer successful.',
    'Transferkarte': 'Transfer Card',
    'Transferkarte löschen?': 'Delete transfer card?',
    'Transferkarten': 'Transfer Cards',
    'Transfers / Andere Lizenzen': 'Transfers / Other Licenses',
    'Ungültiger Dateityp': 'Invalid File Type',
    'Verband': 'Federation',
    'Verbände': 'Federations',
    'Verbergen': 'Hide',
    'verein': 'club',
    'Verein': 'Club',
    'Vereine': 'Clubs',
    'Vereine filtern...': 'Filter Clubs...',
    'Vereinstyp': 'Club Type',
    'von': 'from',
    'Von': 'From',
    'vorname': 'first',
    'Vorname': 'First Name',
    'Weiblich': 'Female',
    'Weiter': 'Next',
    'Wird abgelehnt...': 'Rejecting...',
    'Wird akzeptiert...': 'Accepting...',
    'Wird storniert...': 'Canceling...',
    'Zurück': 'Back',
    'Zurückgezogen': 'Withdrawn',

}