import React from 'react'
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Icon, translate as _, formatDateInput, lz } from '@morawadigital/skynet-framework'
import { getLabel } from '../../fieldDefinition'
import Select from 'react-select'
import { getMonths, isEmpty, splitDate, splitRange } from '../../util'
import { FIELD_TYPE_ADDRESS, FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_DATE, FIELD_TYPE_FLEX_DATE, FIELD_TYPE_MULTI_TEXT, FIELD_TYPE_NUMBER, FIELD_TYPE_RANGE, FIELD_TYPE_SELECT, FIELD_TYPE_TEXTAREA, FIELD_TYPE_TEXTFIELD, FIELD_TYPE_TOGGLE } from '../../util/constants'
import MultiText from './MultiText'

export default class Field extends React.Component {

    #months = getMonths()

    createAddress() {

        return {

            City:           '',
            GoogleMapsLink: '',
            HouseNo:        '',
            PostalCode:     '',
            Street:         '',

        }

    }

    handleAddressChange( e, name, field ) {

        this.handleChange( {

            target: {

                type:  FIELD_TYPE_ADDRESS,
                value: { ...( this.props.value || this.createAddress() ), [ field ]: e.target.value },

                name,

            }

        } )

    }

    handleChange( e ) {

        if ( this.props.onChange ) {

            const name = e.target.name.slice( this.props.field.object.length + 1 )
            let value  = e.target.value

            if ( e.target.type === 'checkbox' ) {

                value = e.target.checked

            } else if ( e.target.type === 'select-one' && value === '' ) {

                value = null

            }

            this.props.onChange( name, value )

        }

    }

    handleFlexDateChange( e, name, field ) {

        const date = splitDate( this.props.value )
        let value  = Number( e.target.value )

        if ( isNaN( value ) || value === 0 ) {

            value = ''

        }

        date[ field ] = value

        if ( typeof date.year  === 'number' ) { date.year  = lz( date.year, 4 ) }
        if ( typeof date.month === 'number' ) { date.month = lz( date.month   ) }
        if ( typeof date.day   === 'number' ) { date.day   = lz( date.day     ) }

        this.handleChange( {

            target: {

                type:  FIELD_TYPE_FLEX_DATE,
                value: date.year + '-' + date.month + '-' + date.day,

                name,

            }

        } )

    }

    handleRangeChange( e, name, field ) {

        const range = splitRange( this.props.value )
        let value   = Number( e.target.value )

        if ( isNaN( value ) || value === 0 ) {

            value = ''

        }

        range[ field ] = value

        this.handleChange( {

            target: {

                type:  FIELD_TYPE_RANGE,
                value: range.from + '-' + range.to,

                name,

            }

        } )

    }

    renderAddress( options ) {

        const value = options.value || this.createAddress()

        return (

            <>

                <InputGroup className='mb-1'>

                    <Form.Control name={ options.name + '-street'           } value={ isEmpty( value.Street         ) ? '' : value.Street         } onChange={ e => this.handleAddressChange( e, options.name, 'Street'         ) } required={ this.props.field.isRequired } placeholder={ _( 'Straße'           ) }                                />

                    <Form.Control name={ options.name + '-house-no'         } value={ isEmpty( value.HouseNo        ) ? '' : value.HouseNo        } onChange={ e => this.handleAddressChange( e, options.name, 'HouseNo'        ) } required={ this.props.field.isRequired } placeholder={ _( 'Hausnummer'       ) } style={ { maxWidth: '8rem' } } />

                </InputGroup>

                <InputGroup className='mb-1'>

                    <Form.Control name={ options.name + '-zip-code'         } value={ isEmpty( value.PostalCode     ) ? '' : value.PostalCode     } onChange={ e => this.handleAddressChange( e, options.name, 'PostalCode'     ) } required={ this.props.field.isRequired } placeholder={ _( 'PLZ'             ) } style={ { maxWidth: '8rem' } } />

                    <Form.Control name={ options.name + '-city'             } value={ isEmpty( value.City           ) ? '' : value.City           } onChange={ e => this.handleAddressChange( e, options.name, 'City'           ) } required={ this.props.field.isRequired } placeholder={ _( 'Ort'             ) }                                />

                </InputGroup>

                <Form.Control     name={ options.name + '-google-maps-link' } value={ isEmpty( value.GoogleMapsLink ) ? '' : value.GoogleMapsLink } onChange={ e => this.handleAddressChange( e, options.name, 'GoogleMapsLink' ) } required={ this.props.field.isRequired } placeholder={ _( 'Google Maps URL' ) }                                />

            </>

        )

    }

    renderAutocomplete( options ) {

        const className = [ 'react-select' ]

        if ( options.required && ( isEmpty( options.value ) || options.value === '' || ( Array.isArray( options.value ) && options.value.length === 0 ) ) ) {

            className.push( 'is-invalid' )

        } else {

            className.push( 'is-valid' )

        }

        return (

            <Select
                className={ className.join( ' ' ) }
                classNamePrefix='react-select'
                isClearable={ true }
                isDisabled={ this.props.disabled }
                noOptionsMessage={ () => _( 'Keine Optionen verfügbar' ) }
                options={ this.props.valueOptions && ( this.props.field.__optionsKey in this.props.valueOptions ) && this.props.valueOptions[ this.props.field.__optionsKey ] ? this.props.valueOptions[ this.props.field.__optionsKey ] : [] }
                placeholder=''
                { ...options }
                onChange={ ( e, f ) => this.handleChange( { target: { type: 'autocomplete', value: e, name: f.name } } ) }
            />

        )

    }

    renderControl() {

        const options = {

            disabled: this.props.disabled,
            name:     this.props.field.object + '-' + this.props.field.property,
            onChange: e => this.handleChange( e ),
            required: this.props.field.isRequired,
            value:    isEmpty( this.props.value ) ? '' : this.props.value,

        }

        switch ( this.props.field.__type ) {

            case FIELD_TYPE_ADDRESS:

                return this.renderAddress( options )

            case FIELD_TYPE_AUTOCOMPLETE:

                return this.renderAutocomplete( options )

            case FIELD_TYPE_DATE:

                options.type = 'date'

                if ( options.value ) {

                    options.value = formatDateInput( options.value )

                }

                return this.renderTextfield( options )

            case FIELD_TYPE_FLEX_DATE:

                return this.renderFlexDate( options )

            case FIELD_TYPE_MULTI_TEXT:

                return this.renderMultiText( options )

            case FIELD_TYPE_NUMBER:

                options.type = 'number'

                return this.renderTextfield( options )

            case FIELD_TYPE_RANGE:

                return this.renderRange( options )

            case FIELD_TYPE_SELECT:

                return this.renderSelect( options )

            case FIELD_TYPE_TEXTAREA:

                options.as = 'textarea'

                return this.renderTextfield( options )

            case FIELD_TYPE_TEXTFIELD:

                return this.renderTextfield( options )

            case FIELD_TYPE_TOGGLE:

                return this.renderToggle( options )

            default:

                return this.renderText( options )

        }

    }

    renderFlexDate( options ) {

        const value = splitDate( options.value )

        // TODO Handle required prop?

        return (

            <InputGroup>

                <Form.Control type='number' name={ options.name + '-day'  } value={ value.day   } onChange={ e => this.handleFlexDateChange( e, options.name, 'day'   ) } placeholder={ _( 'Tag'   ) } style={ { maxWidth: '20%' } } maxLength='2' />

                <Form.Select name={ options.name + '-month' } value={ value.month } onChange={ e => this.handleFlexDateChange( e, options.name, 'month' ) } placeholder={ _( 'Monat' ) }>

                    <option value=''>{ _( 'Monat' ) }</option>

                    { this.#months.map( ( e, i ) => <option key={ i } value={ e.value }>{ e.label }</option> ) }

                </Form.Select>

                <Form.Control type='number' name={ options.name + '-year' } value={ value.year  } onChange={ e => this.handleFlexDateChange( e, options.name, 'year'  ) } placeholder={ _( 'Jahr'  ) } style={ { maxWidth: '30%' } } maxLength='4' />

            </InputGroup>

        )

    }

    renderMultiText( options ) {

        return (

            <MultiText { ...options } />

        )

    }

    renderRange( options ) {

        const value = splitRange( options.value )

        // TODO Handle required prop?

        return (

            <InputGroup>

                <Form.Control type='number' name={ options.name + '-from' } value={ value.from } onChange={ e => this.handleRangeChange( e, options.name, 'from' ) } placeholder={ _( 'Von' ) } />

                <Form.Control type='number' name={ options.name + '-to'   } value={ value.to   } onChange={ e => this.handleRangeChange( e, options.name, 'to'   ) } placeholder={ _( 'Bis' ) } />

            </InputGroup>

        )

    }

    renderSelect( options ) {

        return (

            <Form.Select { ...options }>

                <option value=''></option>

                {

                    this.props.valueOptions &&
                    ( this.props.field.__optionsKey in this.props.valueOptions ) &&
                    this.props.valueOptions[ this.props.field.__optionsKey ] &&
                    this.props.valueOptions[ this.props.field.__optionsKey ].map( ( option, i ) =>

                        <option key={ i } value={ option.value }>{ option.label }</option>

                    )

                }

            </Form.Select>

        )

    }

    renderText( options ) {

        return (

            <Form.Text { ...options }>

                <div className='form-text-vd'>

                    {

                        this.props.field.__displayFn ? this.props.field.__displayFn( options ) : (

                            typeof options.value === 'object' ?

                                JSON.stringify( options.value )

                            : options.value === true ?

                                <Icon icon='check' />

                            : options.value === false ?

                                <Icon icon='times' />

                            :

                                options.value

                        )

                    }

                </div>

            </Form.Text>

        )

    }

    renderTextfield( options ) {

        return (

            <Form.Control { ...options } />

        )

    }

    renderToggle( options ) {

        return (

            <Form.Check { ...options } type='switch' checked={ options.value === true } />

        )

    }

    render() {

        return (

            <Form.Group as={ Row } className='mb-1'>

                <Form.Label column sm='4'>

                    { getLabel( this.props.field ) }

                    { this.props.field.isRequired && <OverlayTrigger overlay={ <Tooltip>{ _( 'Dieses Feld ist verpflichtend.' ) }</Tooltip> }><span className='text-info ms-1'><Icon icon='asterisk' /></span></OverlayTrigger> }

                </Form.Label>

                <Col sm='8'>

                    { this.renderControl() }

                </Col>

            </Form.Group>

        )

    }

}